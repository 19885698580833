.card .breadcrumb {
	padding: 0em;
	background-color: inherit;
}

@media (min-width: 576px) {
	#root .card-deck .card,
	#root .card-columns .card,
	#root .card-group .card {
		min-width: 20rem;
	}
}
